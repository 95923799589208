import React from 'react';

import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom';
import { Home } from '../components/Home';
import { Projects } from '../components/Projects';
import { About } from '../components/About';
import  Curriculum  from '../components/Curriculum';
import { Contact } from '../components/Contact';
import { Project } from '../components/Project';

import { Footer } from '../components/layout/Footer';
import { HeaderNav } from '../components/layout/HeaderNav';


export const Routers = () => {
  return (
    <BrowserRouter>
      {/* Header and Nav */}
      
      <HeaderNav />
      {/* Body = donde se cargan todos los componentes, por lo que definimos cada una de las rutas*/}
      <section className='content'>
        
        <Routes>
          <Route path='/' element={<Navigate to='Home' />}/>
          <Route path='/home' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/projects' element={<Projects/>}/>
          <Route path='/curriculum' element={<Curriculum/>}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/project/:id' element={<Project/>}/>
          <Route path='*' element={
            <div className='page'>
              <h1 className='error'>Error 404</h1>
            </div>
          }/>
        </Routes>
      </section>
      {/* Footer */}
      <Footer />
    </BrowserRouter>
  )
}