import React, { useState } from "react";
import emailjs from "@emailjs/browser";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const serviceId = "service_habnm3r";
    const templateId = "template_02y6py7";
    const publicKey = "3JgI38z532-wusS0u";
  
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Lierni",
      subject: subject,
      message: message,
    };

    // Send email using EmailJS
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email successfully sent', response);
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
      })
      .catch((error) => {
        console.error("Error sending email", error);
      });
  }


  return (
    <div className="contact">
      <div className="contact-container">
        <h1>Send message</h1>

        <form className="contact-form" onSubmit={handleSubmit}>
          <input
          type="text"
          id="name"
          placeholder="Name"
          autoComplete="off"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          id="email"
          placeholder="Email"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          id="subject"
          placeholder="Subject"
          autoComplete="off"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <textarea
          id="message"
          name="message"
          placeholder="Message"
          autoComplete="off"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
          <button type="submit">Send</button>
        </form>
      </div>
    </div>
  );
};
