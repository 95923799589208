import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import './styles/layout.css';
import './styles/body-home.css';
import './styles/body-curriculum.css';
import './styles/body-about.css';
import './styles/body-contact.css';
import './styles/body-projects.css';
import './styles/header.css';
import './styles/footer.css';
import './styles/pdf-button.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);


