export const projects = [
  {
    id: "codepen",
    name: "CodePen.io Homepage Replication",
    tech: "HTML, CSS",
    description:
      "Demonstrate what I have learned about HTML and CSS by replicating the CodePen.io homepage.",
    link: "https://github.com/liernimurgoitio/CodePen.io-Homepage-Replication"
  },
 
  {
    id: "blog_project",
    name: "Mujer 49 plus",
    tech: "HTML, CSS, Javascript, React, Python, MongoDB, Axios, NPM, Git, GitHub",
    description:
    "Development of a blog, with Login, Logout and Register option."
  },

  {
    id: "html_css_page",
    name: "IBM Course Replication",
    tech: "HTML, CSS, Git, GitHub",
    description:
    "Development of a page, with HTML and CSS.",
    link: "https://github.com/liernimurgoitio/pagina-web-html-css"
  },

  {
    id: "gestor_de_tareas",
    name: "BeJob-IBM Final Project",
    tech: "HTML, CSS, Git, GitHub, Python, Django, Algorithms ",
    description:
    "Development of a to-do list program.",
    link: "https://github.com/liernimurgoitio/FULL-STACK-PYTHON---CASO-PRACTICO-FINAL",
    videoLink: "/videos/2024-06-14-16-11-05.mp4"
  }
];
