import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "../styles/body-home.css";

export const Home = () => {
  const [headerContent, setHeaderContent] = useState("");
  const [showFullStackDeveloper, setShowFullStackDeveloper] = useState(false);

  useEffect(() => {
    const blinkCursors = document.querySelectorAll(".blink-cursor");

    let currentIndex = 0;

    const intervalId = setInterval(() => {
      blinkCursors.forEach((cursor, index) => {
        if (currentIndex === index) {
          cursor.classList.add("active");
        } else {
          cursor.classList.remove("active");
        }
      });

      currentIndex = (currentIndex + 1) % (blinkCursors.length + 1); // Ajustar la longitud
    }, 500);

    setTimeout(() => {
      setShowFullStackDeveloper(true);
    }, 500); // Esperar medio segundo antes de mostrar "Full Stack Developer"

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const headerText = " Junior Full Stack Developer";

    const updateHeaderText = () => {
      let i = 0;
      const intervalId = setInterval(() => {
        setHeaderContent((prevContent) => {
          const newContent = prevContent + headerText[i];
          i++;

          if (i === headerText.length) {
            clearInterval(intervalId);
            setTimeout(() => {
              setHeaderContent(""); // Reiniciar el contenido después de medio segundo
              setTimeout(() => {
                setShowFullStackDeveloper(false); // Ocultar Full Stack Developer después de medio segundo
                setTimeout(() => {
                  setShowFullStackDeveloper(true); // Mostrar Full Stack Developer después de medio segundo
                }, 500);
              }, 500);
            }, 1000);
          }

          return newContent;
        });
      }, 500);
    };

    if (showFullStackDeveloper) {
      updateHeaderText();
    }
  }, [showFullStackDeveloper]);

  return (
    <div id="pagina-especial">
      <div className="hero-home">
        <h1 className="hero-head">
          Lierni Murgoitio
          <span className="blink-cursor blink-cursor-1">_</span>
          <span className="blink-cursor blink-cursor-2">_</span>
          <span className="blink-cursor blink-cursor-3">_</span>
          {headerContent && <span>&nbsp;&nbsp;</span>}
          {headerContent}
        </h1>

        <div className="socials-icons-home">
          <a
            href="https://www.linkedin.com/in/liernimurgoitio/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>

          <a
            href="https://github.com/liernimurgoitio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-brands fa-github"></i>
          </a>
        </div>

        <div className="hero-links">
          <NavLink to="/about">ABOUT ME</NavLink>{" "}
          <NavLink to="/projects">PROJECTS</NavLink>
          <NavLink to="/curriculum">CURRICULUM</NavLink>
          <NavLink to="/contact">CONTACT</NavLink>
        </div>
      </div>
    </div>
  );
};
