
import React from "react";
import { Link } from "react-router-dom";
import { projects } from "../data/projects";

import "../styles/layout.css";
import "../styles/body-projects.css";

export const Projects = () => {
  return (
    <div className="main">
      <div className="projects-header">Projects</div>
      <div className="projects-container">
        <section className="projects-section">
          {projects.map((project) => {
            return (
              <article key={project.id} className="work-item">
                <div className="mask-image">
                  <img src={"/images/" + project.id + ".png"} alt="" />
                </div>
                <div className="project-details">
                  <span>{project.category}</span>
                  <h2>
                    <Link to={"/project/" + project.id}>{project.name}</Link>
                  </h2>
                  <p>{project.description}</p>
                  <div className="tech-link-img">
                    <h3>{project.tech}</h3>
                    <div className="link">
                      <a target="_blank" href={project.link} rel="noreferrer">
                        Code
                        <img
                          src="../images/github.png"
                          alt=""
                          className="github-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </article>
            );
          })}
        </section>
      </div>
    </div>
  );
};
