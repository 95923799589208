import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { projects } from '../data/projects';
import "../styles/body-project.css";

export const Project = () => {
  const [project, setProject] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let project = projects.filter(project => project.id === params.id);
    setProject(project[0]);
  }, [params.id]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className='single-project-container'>
      <h1 className='single-heading'><strong>{project.name}</strong></h1>
      <p><strong>Learned  technology:</strong> {project.tech}</p>
      <p><strong>Description:</strong> {project.description}</p>
      <div className='single-project-details'>
        <div className='single-project-mask'>
          <img src={'/images/' + project.id + '.png'} alt='' className='single-image' />
        </div>
        {project.videoLink && (
          <div className='video-container'>
            <video controls>
              <source src={project.videoLink} type='video/mp4' />
            </video>
          </div>
        )}
      </div>
      <button onClick={goBack}>Go Back</button>
    </div>
  );
};
