import './App.css';

import { Routers } from './router/Routers';

function App() {
  return (
    <div className='layout'>
      <Routers/>
    </div>
  );
}

export default App;