import React from "react";

import "../styles/layout.css";
import "../styles/body-about.css";
import LierniMurgoitioImage from "../images/Lierni Murgoitio.png";
import { Link } from "react-router-dom";
export const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <div className="my-name">
          <h1>¡Hola! Soy Lierni.</h1> 
          
          <p>Me apasiona el mundo de la informática y desde el año 2018 he estado enfocándome en adquirir conocimientos y habilidades en este campo en constante evolución.</p>

          <p>
          Durante este tiempo, he invertido mi tiempo y esfuerzo en estudiar y completar cursos relevantes, como mi titulación de <b>Técnica Superior en Sistemas de Telecomunicaciones e Informáticos</b> en 2020. Además, he participado en programas como <b>DEVELOPERS FROM EUSKADI</b> obteniendo el certificado <b>CERTIFICATE OF COMPLETION OF FULL STACK PROGRAM 2023</b>, impartido por BOTTEGA en colaboración con LANBIDE y la FUNDACION VASS.
          </p>
          
          <p>
          En mayo de 2024 he ampliado mis conocimientos aún más al obtener el certificado <b>Python Full Stack</b> impartido por IBM en colaboración con BeJob. Esta experiencia me ha permitido explorar las capacidades de Python y consolidar mis habilidades como desarrolladora full stack.
          </p>

          <p>
          Aunque aún no cuento con experiencia laboral formal en el campo de la informática, mis proyectos y certificaciones demuestran mi dedicación, pasión y habilidades técnicas. Estoy emocionada por comenzar mi carrera profesional y enfrentar nuevos desafíos en el ámbito laboral.
          </p>

          <p>
          Soy una persona creativa, curiosa y apasionada por aprender. Me encanta estar al tanto de las últimas tendencias y tecnologías en el campo de la informática. Mi enfoque es seguir creciendo como profesional y contribuir al desarrollo de soluciones innovadoras en el mundo digital.
          </p>

          <p>
          Estoy abierta a oportunidades laborales y colaboraciones que me permitan seguir aprendiendo y adquiriendo experiencia. Creo firmemente en el poder del trabajo en equipo y estoy emocionadoa por formar parte de proyectos que impulsen el crecimiento y el éxito.
          </p>

          <p>
          Gracias por visitar mi perfil y por considerar mi potencial. Si tienes alguna oportunidad o proyecto interesante en el que creas que puedo encajar, ¡me encantaría saber de ti! Estoy dispuesta a enfrentar nuevos desafíos y aportar mis habilidades y conocimientos al equipo.
          </p>

          <p>
          ¡Espero tener la oportunidad de trabajar juntos y contribuir al avance de la informática!"
            <Link to="/contact">contacta conmigo.</Link>
          </p>
        </div>

        <div className="image-right">
          <img src={LierniMurgoitioImage} alt="Lierni Murgoitio.png" />
        </div>
      </div>
    </div>
  );
};
