
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";

import "../../styles/layout.css";
import "../../styles/header.css";

export const HeaderNav = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  // Lista de rutas donde no quiero mostrar el HeaderNav
  const routesWithoutHeaderNav = ["/home"]; // Agrego la ruta que quiero

  // Verifica si la ruta actual está en la lista
  const shouldShowHeaderNav = !routesWithoutHeaderNav.includes(
    location.pathname
  );

  if (!shouldShowHeaderNav) {
    return null; // No renderizar HeaderNav si la ruta actual está en la lista
  }

  
  const navLinks = [
    { to: "/home", label: "Home" },
    { to: "/about", label: "About me" },
    { to: "/projects", label: "Projects" },
    { to: "/curriculum", label: "Curriculum" },
    { to: "/contact", label: "Contact" },
  ];

  
  return (
    <header className="header">
      <div className="container">
        <div className="logo">
          <div className="circle-logo">
            <div className="letter-logo">LM</div>
          </div>
        </div>

        <button className="mobile-menu-icon" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
          
        <nav className={isMobileMenuOpen? "nav-mobile" : "nav"} onClick={()=> setIsMobileMenuOpen(false)}>
          <ul className="ul">
            {navLinks.map((link) => (
              <li key={link.to}>
                <NavLink to={link.to} activeclassname="active">
                  {link.label}
                </NavLink>
              </li>
            ))}
          </ul>
          <ul className="socials">
            <a
              href="https://www.linkedin.com/in/liernimurgoitio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-linkedin"></i>
            </a>

            <a
              href="https://github.com/liernimurgoitio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-github"></i>
            </a>
          </ul>
          
        </nav>
      </div>
    </header>
  );
};
